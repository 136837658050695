import React from "react";

function Hero4() {
  return (
    <div id="objective" className="hero-content bg-light">
      <div className="hero-subtext text-dark m-auto">
        <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6 strong mb-3">
        Tenemos el <strong>compromiso</strong> con nuestros clientes hasta que esten totalmente satisfechos.
        </div>
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          <p className="h3">Contando con un equipo <strong>altamente capacitado</strong>, trabajamos en estrecha colaboración con nuestros clientes bajo nuestra <strong>Política de Satisfacción</strong>, comprometiéndonos a ofrecer un servicio de excelencia que busca la plena satisfacción de cada cliente.</p>
        </div>
        </div>
      
      </div>
    </div>
  );
}

export default Hero4;
 