import React, { useState } from "react";
import TestimonialSlider from './TestimonialSlider';


const sendEmail = async (from, text) => {
  const emailData = {
    from: from,
    subject: "Hello",
    text: text,
  };

  try {
    const response = await fetch("/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    });

    if (response.ok) {
      return true; // Email sent successfully
    } else {
      throw new Error("Failed to send email");
    }
  } catch (error) {
    console.error("Error sending email:", error);
    return false; // Failed to send email
  }
};

function Hero5() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailSent, setEmailSent] = useState(null); // null, true, or false
  const [isLoading, setIsLoading] = useState(false);

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (name && phone) {
      setIsLoading(true); // Start loading
      const success = await sendEmail(phone, name);
      setEmailSent(success);
      console.log("handleSubmit: Email sent status:", success);
      setIsLoading(false); // Start loading
    } else {
      alert("Please fill in all fields.");
    }
  };


  return (
    <div id="contact" className="hero-content">
      <TestimonialSlider />

      <div className="container" style={{marginTop: "80px"}}>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6 pt-1">
            <p className="h5">Contacta <strong>+34 631 606 811</strong></p>
            <p className="h5">Escríbenos <strong>info@gruponeteco.com</strong></p>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <form onSubmit={handleSubmit}>
              <p className="h4 strong"> Nos ponemos en contacto contigo en menos de 24/48h:</p>
              <div className="form-controls row mx-0 mt-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre"
                  value={name}
                  required
                  onChange={handleNameChange}
                ></input>
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Telefono"
                  required
                  value={phone}
                  onChange={handlePhoneChange}
                />
                <button className="btn" type="submit" disabled={isLoading}>
                  {isLoading ? "Enviando..." : "Enviar"}
                </button>
                {emailSent === true && (
                  <div>
                    ¡Hemos recibido tu solicitud! Te contactaremos en breves.
                  </div>
                )}
                {emailSent === false && (
                  <div>Estamos teniendo problemas, prueba llamandonos por telefono al +34 631 606 811</div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero5;
