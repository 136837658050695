import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  
    {
    text: "No puedo estar más contenta con el trabajo de los operarios, profesionalidad, educación y resultado ,han dejado el piso como nuevo, con olor a limpio muy agradable. Igualmente con la atención recibida al solicitar presupuesto, que aclararon todas mis dudas, que eran muchas, recordatorio el día de antes de la limpieza y el mismo día informando ...",
    author: "Rosa Rico",
    date: "18-09-2023",
    color: "#ab47bc",
    link : "https://g.co/kgs/43eWsb"
  },
  {
    text: "Primera vez y por supuesto repetiré. \nPuntuales, trabajadores ,serios, limpios con la estancia, la limpieza de las ventanas todo...",
    author: "Ana Manfredi",
    date: "23-12-2022",
    color: "#9B7F65",
    link : "https://g.co/kgs/HFAZy9"
  },
  {
    text: "Me hicieron una limpieza excepcional, un gran trabajo, gracias por servicio, lo recomiendo",
    author: "Eunice Hidalgo Gomez",
    date: "27-01-2023",
    color: "#BD00FF",
    link: "https://g.co/kgs/gH2rs4"
  },
  {
    text: "Buen servicio y muy atentos",
    author: "Francisco Magaña",
    date: "13-11-2022",
    color: "#FF7A00",
    link: "https://g.co/kgs/9ssDKD"
  },
  {
    text: "Lo recomiendo bastante, son grandes profesionales y tienen buen trato al cliente. Te contestan enseguida, se adaptan a tus intereses, y ofrecen ampli...",
    author: "Desire Niell",
    date: "27-01-2023",
    color: "#DF2F79",
    link: "https://g.co/kgs/zhdmaf"
  },
  
];

function TestimonialSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container p-10">
      <p className="text-center h2" style={{margin: "45px 0px"}}>Nuestro clientes opinan</p>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <div className="testimonial-card text-dark px-4 py-3">
              <div className="d-flex justify-content-between align-items-start">
                <div className="testimonial-rating">★★★★★</div>
                <div className="logo-google"></div>
              </div>
              <div className="testimonial-text">{testimonial.text}</div>
            </div>
            <div className="testimonial-arrow"></div>
            <div className="testimonial-footer">
              <div
                className="testimonial-author-initials"
                style={{ backgroundColor: testimonial.color }}
              >
                {testimonial.author[0]}
              </div>
              <div className="testimonial-author-info">
                <div className="testimonial-author">{testimonial.author}</div>
                <div className="testimonial-date">{testimonial.date}</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default TestimonialSlider;
