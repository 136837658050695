import React from "react";

function Hero2() {
  return (
    <div id="messages" className="hero-content bg-light">
      <div className="hero-subtext text-dark text-center m-auto">
        <p className="py-2">
          Nos dedicamos a proporcionar un <strong>servicio excepcional</strong> que deja su espacio impecable y su mente tranquila.
        </p>
        <p className="py-2">
          Nuestro <strong>equipo</strong> de especialistas está altamente
          capacitado en la limpieza de todo tipo de espacios.
        </p>
        <p className="h6 py-2">
          Desde una limpieza basíca hasta una limpieza en total profundidad.
        </p>
      </div>
    </div>
  );
}

export default Hero2;
 