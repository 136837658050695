import React from "react";

function Header() {
  return (
    <div className="header position-absolute w-100 pt-3">
      <div className="header-links mt-1">
        <a href="#services">Servicios</a>
        <a href="#objective">Objetivo</a>
        <a href="#contact">Contacto</a>
      </div>
    </div>
  );
}

export default Header;
