import React, { useState } from "react";

const sendEmail = async (from, text) => {
  const emailData = {
    from: from,
    subject: "Hello",
    text: text,
  };

  try {
    const response = await fetch("/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    });

    if (response.ok) {
      return true; // Email sent successfully
    } else {
      throw new Error("Failed to send email");
    }
  } catch (error) {
    console.error("Error sending email:", error);
    return false; // Failed to send email
  }
};

function Hero() {
  const [email, setEmail] = useState("");
  const [interest, setInterest] = useState("");
  const [emailSent, setEmailSent] = useState(null); // null, true, or false
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleInterestChange = (event) => {
    setInterest(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (interest && email) {
      setIsLoading(true); // Start loading
      const success = await sendEmail(email, interest);
      setEmailSent(success);
      console.log("handleSubmit: Email sent status:", success);
      setIsLoading(false); // Start loading
    } else {
      alert("Please fill in all fields.");
    }
  };

  const handleScroll = (e) => {
    e.preventDefault()
    // Assuming the next div has an id of "next-section"
    document.getElementById("images").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      <form className="hero-content pb-0 mb-0" onSubmit={handleSubmit}>
        <div className="hero-title align-left mt-auto">
          <strong>Neteco</strong>
        </div>
        <div className="hero-subtext">
          Haz que nuestro equipo de <strong>limpieza</strong> transforme su
          espacio en un lugar <strong>reluciente</strong>
        </div>

        <div className="form-controls row mx-0 mt-4">
          <select
            className="form-control"
            value={interest}
            required
            onChange={handleInterestChange}
          >
            <option value="">Selecciona un servicio</option> {/* Empty option */}
            <option value="obra">Limpiezas de final de obra</option>
            <option value="inquilinos">Salida de inquilinos</option>
            <option value="comunidades">Limpieza de comunidades</option>
            <option value="oficinas">Limpieza de oficinas</option>
            <option value="xhoras">Limpieza por horas</option>
          </select>
          <input
            type="email"
            className="form-control"
            placeholder="name@email.com"
            required
            value={email}
            onChange={handleEmailChange}
          />
          <button className="btn" type="submit" disabled={isLoading}>
            {isLoading ? "Enviando..." : "Enviar"}
          </button>
          {emailSent === true && (
            <div>¡Hemos recibido tu solicitud! Te contactaremos en breves.</div>
          )}
          {emailSent === false && (
            <div>Estamos teniendo problemas, prueba llamandonos por telefono al +34 936 274 046</div>
          )}
        </div>

        <div className="w-25 mx-auto text-center my-0 py-5">
          <button onClick={handleScroll} className="btn-scroll-down">
            <svg
              width="45"
              height="45"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="bi bi-arrow-down-circle-fill"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"
              />
            </svg>
          </button>
        </div>
      </form>
    </>
  );
}

export default Hero;
