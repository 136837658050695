import React from "react";

function GridPicture3() {
  // Array of image URLs. Replace these with your own images.
  const images = [
    "/images/img-13.png",
    "/images/img-14.png",
  ];

  return (
    <div id="images3" className="row mx-0">
      {images.map((image, index) => (
        <div key={index} className="col-12 p-0">
          <img src={image} alt={`Description of  ${index + 1}`} className="img-fluid w-100" />
        </div>
      ))}
    </div>
  );
}

export default GridPicture3;
