import React from 'react';
import './App.css';
import Hero from './components/Hero';
import Hero2 from './components/Hero2';
import Hero3 from './components/Hero3';
import Hero4 from './components/Hero4';
import Hero5 from './components/Hero5';

import Header from './components/Header';
import Header2 from './components/Header2';
import Header3 from './components/Header3';
import Header4 from './components/Header4';
import Header5 from './components/Header5';
import GridPictures from './components/GridPictures';
import GridPicture2 from './components/GridPicture2';
import GridPicture3 from './components/GridPicture3';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <GridPictures/>
      <Header2 />
      <Hero2 />
      <GridPicture2/>
      <Header3 />
      <Hero3 />
      <Header4 />
      <Hero4 />
      <GridPicture3/>
      <Header5 />
      <Hero5 />
      <Footer/>
    </div>
  );
}

export default App;
